@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Rubik:400,700");


table {
    text-align: center;
    border-collapse: collapse;
    width: 100%;
}

thead {
    background-color: #393939;
    color: white;
}

tr:hover td {
    background-color: #fafafa;
}


.tex-align-h1 {
    width: 100%;
    text-align: left;
}

/*Diseño de de tablas*/
.Conteiner-table {
    width: 100%;
    height: auto;
    margin-top: 20px;
    overflow-x: auto;
    overflow-y: hidden;
}

/************************/

.Conteiner-table::-webkit-scrollbar {
    width: 8px; /* Tamaño del scroll en vertical */
    height: 8px; /* Tamaño del scroll en horizontal */
    /*display: none;*/
}

.Conteiner-table::-webkit-scrollbar-thumb {
    background: #a8a8a8;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
.Conteiner-table::-webkit-scrollbar-thumb:hover {
    background: #393939;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Ponemos un color de fondo y redondeamos las esquinas del track */
.Conteiner-table::-webkit-scrollbar-track {
    background: #e1e1e1;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

/************************/

/************************/

 tbody::-webkit-scrollbar,.table-installation tbody::-webkit-scrollbar {
    width: 8px; /* Tamaño del scroll en vertical */
    height: 8px; /* Tamaño del scroll en horizontal */
    /*display: none;*/
}

tbody::-webkit-scrollbar-thumb {
    background: #a8a8a8;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
 tbody::-webkit-scrollbar-thumb:hover {
    background: #13296a;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Ponemos un color de fondo y redondeamos las esquinas del track */
tbody::-webkit-scrollbar-track {
    background: #e1e1e1;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

/************************/

.table-post tr, td, th {
    border: 1px solid #eeedec;
    font-size: 13px;
    font-weight: 100;
    padding: 10px;
}

.table-post tr {
    color: red;
}

/*table provedores*/

.table-providers tr, .table-providers td, .table-providers th {
    width: 237px;
}
.table-providers td {
    color: #787878;
}

.table-providers tbody {
    width: 4275px;
    height: 350px;
    display: block;
    overflow: auto;
}

.table-providers {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
}
/******************************************************/

/*table ordenes*/

.table-info-product1 tr, .table-info-product1 td, .table-info-product1 th {
    width: 200px;
}
.table-info-product1 td {
    color: #787878;
}
.table-info-product1{
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
}
/******************************************************/

/******************************************************/


.select-table {
    margin-top: 10px;
    min-width: 40px;
    width: 100%;
    background: url(../assets/icons/iconos-bajar-negro-selec.png) no-repeat 90% center;
}

.on-off {
    margin-top: 14px;
    min-width: 20px;
    width: 100%;
    background: url(../assets/icons/iconos-bajar-negro-selec.png) no-repeat 80% center;
}

@media screen and (min-width: 760px) {
    .Btn-general {
        justify-items: right;
    }

    .Btn-general-filter {
        grid-template-columns: 1fr;
    }

    .container-add-direction {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
    }

    .container-add-direction2 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 10px;
    }

    .btn-general-only {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .div-btn-add2 {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 100%;
    }

    .Btn-general-config {
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        justify-items: center;
    }

    .Btn-buscar {
        width: 200px;
    }

    .div-btn-filter {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
        width: 100%;
    }
}

@media screen and (min-width: 1024px) {
    .Btn-general-filter {
        grid-template-columns: 1fr 1fr;
    }
    .container-add-direction {
        display: grid;
        grid-template-columns: 1fr 1fr ;
        grid-gap: 10px;
    }
    .container-add-direction2 {
        display: grid;
        grid-template-columns: 1fr  1fr 1fr;
        grid-gap: 10px;
    }
}

/*terminan tablas*/
