@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?95215411');
  src: url('../font/fontello.eot?95215411#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?95215411') format('woff2'),
       url('../font/fontello.woff?95215411') format('woff'),
       url('../font/fontello.ttf?95215411') format('truetype'),
       url('../font/fontello.svg?95215411#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?95215411#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-user-add:before { content: '\e800'; } /* '' */
.icon-camera:before { content: '\e801'; } /* '' */
.icon-music:before { content: '\e802'; } /* '' */
.icon-star:before { content: '\e803'; } /* '' */
.icon-download:before { content: '\e804'; } /* '' */
.icon-upload:before { content: '\e805'; } /* '' */
.icon-tags:before { content: '\e806'; } /* '' */
.icon-map:before { content: '\e807'; } /* '' */
.icon-direction-outline:before { content: '\e808'; } /* '' */
.icon-location:before { content: '\e809'; } /* '' */
.icon-rss:before { content: '\e80a'; } /* '' */
.icon-flash:before { content: '\e80b'; } /* '' */
.icon-sun-filled:before { content: '\e80c'; } /* '' */
.icon-globe:before { content: '\e80d'; } /* '' */
.icon-stopwatch:before { content: '\e80e'; } /* '' */
.icon-android:before { content: '\e80f'; } /* '' */
.icon-phone:before { content: '\e810'; } /* '' */
.icon-windows:before { content: '\e811'; } /* '' */
.icon-appstore:before { content: '\e812'; } /* '' */
.icon-comment-alt:before { content: '\e813'; } /* '' */
.icon-chat:before { content: '\e814'; } /* '' */
.icon-reply-all:before { content: '\e815'; } /* '' */
.icon-reply:before { content: '\e816'; } /* '' */
.icon-folder:before { content: '\e817'; } /* '' */
.icon-chart-bar:before { content: '\e818'; } /* '' */
.icon-mic:before { content: '\e819'; } /* '' */
.icon-trophy:before { content: '\e81a'; } /* '' */
.icon-rocket:before { content: '\e81b'; } /* '' */
.icon-down-dir:before { content: '\e81c'; } /* '' */
.icon-left-dir:before { content: '\e81d'; } /* '' */
.icon-right-dir:before { content: '\e81e'; } /* '' */
.icon-up-dir:before { content: '\e81f'; } /* '' */
.icon-thumbs-up:before { content: '\e820'; } /* '' */
.icon-thumbs-down:before { content: '\e821'; } /* '' */
.icon-bell:before { content: '\e822'; } /* '' */
.icon-comment:before { content: '\e823'; } /* '' */
.icon-lock:before { content: '\e824'; } /* '' */
.icon-lock-open:before { content: '\e825'; } /* '' */
.icon-paper-plane:before { content: '\e826'; } /* '' */
.icon-flight:before { content: '\e827'; } /* '' */
.icon-trash:before { content: '\e828'; } /* '' */
.icon-credit-card:before { content: '\e829'; } /* '' */
.icon-plus-circled:before { content: '\e82a'; } /* '' */
.icon-cancel-squared:before { content: '\e82b'; } /* '' */
.icon-database:before { content: '\e82c'; } /* '' */
.icon-videocam:before { content: '\e82d'; } /* '' */
.icon-picture:before { content: '\e82e'; } /* '' */
.icon-camera-1:before { content: '\e82f'; } /* '' */
.icon-th-large:before { content: '\e830'; } /* '' */
.icon-th-list:before { content: '\e831'; } /* '' */
.icon-skype:before { content: '\e832'; } /* '' */
.icon-mail:before { content: '\e833'; } /* '' */
.icon-glass:before { content: '\e834'; } /* '' */
.icon-music-1:before { content: '\e835'; } /* '' */
.icon-mail-1:before { content: '\e836'; } /* '' */
.icon-heart:before { content: '\e837'; } /* '' */
.icon-heart-empty:before { content: '\e838'; } /* '' */
.icon-star-1:before { content: '\e839'; } /* '' */
.icon-star-empty:before { content: '\e83a'; } /* '' */
.icon-users:before { content: '\e83b'; } /* '' */
.icon-ok:before { content: '\e83c'; } /* '' */
.icon-ok-circled-1:before { content: '\e83d'; } /* '' */
.icon-th-large-1:before { content: '\e83e'; } /* '' */
.icon-camera-alt:before { content: '\e83f'; } /* '' */
.icon-camera-2:before { content: '\e840'; } /* '' */
.icon-picture-1:before { content: '\e841'; } /* '' */
.icon-plus:before { content: '\e842'; } /* '' */
.icon-minus:before { content: '\e843'; } /* '' */
.icon-home:before { content: '\e844'; } /* '' */
.icon-lock-open-1:before { content: '\e845'; } /* '' */
.icon-pin:before { content: '\e846'; } /* '' */
.icon-tag:before { content: '\e847'; } /* '' */
.icon-tags-1:before { content: '\e848'; } /* '' */
.icon-bookmark:before { content: '\e849'; } /* '' */
.icon-flag:before { content: '\e84a'; } /* '' */
.icon-download-1:before { content: '\e84b'; } /* '' */
.icon-upload-1:before { content: '\e84c'; } /* '' */
.icon-forward:before { content: '\e84d'; } /* '' */
.icon-print:before { content: '\e84e'; } /* '' */
.icon-comment-1:before { content: '\e84f'; } /* '' */
.icon-chat-1:before { content: '\e850'; } /* '' */
.icon-bell-1:before { content: '\e851'; } /* '' */
.icon-attention:before { content: '\e852'; } /* '' */
.icon-doc:before { content: '\e853'; } /* '' */
.icon-folder-1:before { content: '\e854'; } /* '' */
.icon-folder-open:before { content: '\e855'; } /* '' */
.icon-cog:before { content: '\e856'; } /* '' */
.icon-cog-alt:before { content: '\e857'; } /* '' */
.icon-wrench:before { content: '\e858'; } /* '' */
.icon-basket:before { content: '\e859'; } /* '' */
.icon-calendar:before { content: '\e85a'; } /* '' */
.icon-login:before { content: '\e85b'; } /* '' */
.icon-logout:before { content: '\e85c'; } /* '' */
.icon-down-dir-1:before { content: '\e85d'; } /* '' */
.icon-up-dir-1:before { content: '\e85e'; } /* '' */
.icon-left-dir-1:before { content: '\e85f'; } /* '' */
.icon-right-dir-1:before { content: '\e860'; } /* '' */
.icon-down-open:before { content: '\e861'; } /* '' */
.icon-left-open:before { content: '\e862'; } /* '' */
.icon-right-open:before { content: '\e863'; } /* '' */
.icon-up-open:before { content: '\e864'; } /* '' */
.icon-down-big:before { content: '\e865'; } /* '' */
.icon-left-big:before { content: '\e866'; } /* '' */
.icon-right-big:before { content: '\e867'; } /* '' */
.icon-up-big:before { content: '\e868'; } /* '' */
.icon-play:before { content: '\e869'; } /* '' */
.icon-stop:before { content: '\e86a'; } /* '' */
.icon-to-start-alt:before { content: '\e86b'; } /* '' */
.icon-fast-fw:before { content: '\e86c'; } /* '' */
.icon-fast-bw:before { content: '\e86d'; } /* '' */
.icon-eject:before { content: '\e86e'; } /* '' */
.icon-award:before { content: '\e86f'; } /* '' */
.icon-inbox:before { content: '\e870'; } /* '' */
.icon-globe-1:before { content: '\e871'; } /* '' */
.icon-cloud:before { content: '\e872'; } /* '' */
.icon-flash-1:before { content: '\e873'; } /* '' */
.icon-umbrella:before { content: '\e874'; } /* '' */
.icon-flight-1:before { content: '\e875'; } /* '' */
.icon-list:before { content: '\e876'; } /* '' */
.icon-briefcase:before { content: '\e877'; } /* '' */
.icon-off:before { content: '\e878'; } /* '' */
.icon-road:before { content: '\e879'; } /* '' */
.icon-book:before { content: '\e87a'; } /* '' */
.icon-asterisk:before { content: '\e87b'; } /* '' */
.icon-magnet:before { content: '\e87c'; } /* '' */
.icon-megaphone:before { content: '\e87d'; } /* '' */
.icon-truck:before { content: '\e87e'; } /* '' */
.icon-mail-2:before { content: '\e87f'; } /* '' */
.icon-heart-1:before { content: '\e880'; } /* '' */
.icon-star-2:before { content: '\e881'; } /* '' */
.icon-star-empty-1:before { content: '\e882'; } /* '' */
.icon-mobile:before { content: '\e883'; } /* '' */
.icon-monitor:before { content: '\e884'; } /* '' */
.icon-cd:before { content: '\e885'; } /* '' */
.icon-heart-filled:before { content: '\e886'; } /* '' */
.icon-home-1:before { content: '\e887'; } /* '' */
.icon-home-outline:before { content: '\e888'; } /* '' */
.icon-cog-1:before { content: '\e889'; } /* '' */
.icon-phone-1:before { content: '\e88a'; } /* '' */
.icon-location-outline:before { content: '\e88b'; } /* '' */
.icon-ok-1:before { content: '\e88c'; } /* '' */
.icon-rss-2:before { content: '\e88d'; } /* '' */
.icon-gmail:before { content: '\e88e'; } /* '' */
.icon-ok-2:before { content: '\e88f'; } /* '' */
.icon-cancel:before { content: '\e890'; } /* '' */
.icon-attach:before { content: '\e891'; } /* '' */
.icon-link:before { content: '\e892'; } /* '' */
.icon-accessibility:before { content: '\e893'; } /* '' */
.icon-resize-full:before { content: '\e894'; } /* '' */
.icon-resize-full-alt:before { content: '\e895'; } /* '' */
.icon-volume-down:before { content: '\e896'; } /* '' */
.icon-volume:before { content: '\e897'; } /* '' */
.icon-ok-3:before { content: '\e898'; } /* '' */
.icon-th:before { content: '\e899'; } /* '' */
.icon-th-large-2:before { content: '\e89a'; } /* '' */
.icon-photo:before { content: '\e89b'; } /* '' */
.icon-picture-2:before { content: '\e89c'; } /* '' */
.icon-plus-1:before { content: '\e89d'; } /* '' */
.icon-minus-1:before { content: '\e89e'; } /* '' */
.icon-video:before { content: '\e89f'; } /* '' */
.icon-torso:before { content: '\e8a0'; } /* '' */
.icon-user:before { content: '\e8a1'; } /* '' */
.icon-star-3:before { content: '\e8a2'; } /* '' */
.icon-heart-2:before { content: '\e8a3'; } /* '' */
.icon-heart-circled:before { content: '\e8a4'; } /* '' */
.icon-twitter-2:before { content: '\e8a5'; } /* '' */
.icon-paypal-1:before { content: '\e8a6'; } /* '' */
.icon-delicious-1:before { content: '\e8a7'; } /* '' */
.icon-restaurant:before { content: '\e8a8'; } /* '' */
.icon-export:before { content: '\e8a9'; } /* '' */
.icon-ticket:before { content: '\e8aa'; } /* '' */
.icon-floppy:before { content: '\e8ab'; } /* '' */
.icon-clipboard:before { content: '\e8ac'; } /* '' */
.icon-megaphone-1:before { content: '\e8ad'; } /* '' */
.icon-compass:before { content: '\e8ae'; } /* '' */
.icon-bookmark-1:before { content: '\e8af'; } /* '' */
.icon-chat-2:before { content: '\e8b0'; } /* '' */
.icon-book-1:before { content: '\e8b1'; } /* '' */
.icon-note:before { content: '\e8b2'; } /* '' */
.icon-location-1:before { content: '\e8b3'; } /* '' */
.icon-pencil:before { content: '\e8b4'; } /* '' */
.icon-attach-1:before { content: '\e8b5'; } /* '' */
.icon-lock-1:before { content: '\e8b6'; } /* '' */
.icon-cancel-circled:before { content: '\e8b7'; } /* '' */
.icon-plus-circled-1:before { content: '\e8b8'; } /* '' */
.icon-pencil-1:before { content: '\e8b9'; } /* '' */
.icon-forward-1:before { content: '\e8ba'; } /* '' */
.icon-download-2:before { content: '\e8bb'; } /* '' */
.icon-upload-2:before { content: '\e8bc'; } /* '' */
.icon-phone-2:before { content: '\e8bd'; } /* '' */
.icon-cog-2:before { content: '\e8be'; } /* '' */
.icon-share-1:before { content: '\e8bf'; } /* '' */
.icon-clock:before { content: '\e8c0'; } /* '' */
.icon-search:before { content: '\e8c1'; } /* '' */
.icon-search-1:before { content: '\e8c2'; } /* '' */
.icon-search-2:before { content: '\e8c3'; } /* '' */
.icon-search-3:before { content: '\e8c4'; } /* '' */
.icon-paper-plane-2:before { content: '\f01d'; } /* '' */
.icon-location-2:before { content: '\f031'; } /* '' */
.icon-move:before { content: '\f047'; } /* '' */
.icon-user-female:before { content: '\f063'; } /* '' */
.icon-users-1:before { content: '\f064'; } /* '' */
.icon-ok-circled:before { content: '\f06d'; } /* '' */
.icon-phone-squared:before { content: '\f098'; } /* '' */
.icon-twitter:before { content: '\f099'; } /* '' */
.icon-rss-1:before { content: '\f09e'; } /* '' */
.icon-certificate:before { content: '\f0a3'; } /* '' */
.icon-filter:before { content: '\f0b0'; } /* '' */
.icon-docs:before { content: '\f0c5'; } /* '' */
.icon-menu:before { content: '\f0c9'; } /* '' */
.icon-table:before { content: '\f0ce'; } /* '' */
.icon-pinterest-circled:before { content: '\f0d2'; } /* '' */
.icon-pinterest-squared:before { content: '\f0d3'; } /* '' */
.icon-gplus-squared:before { content: '\f0d4'; } /* '' */
.icon-gplus:before { content: '\f0d5'; } /* '' */
.icon-money:before { content: '\f0d6'; } /* '' */
.icon-mail-alt:before { content: '\f0e0'; } /* '' */
.icon-linkedin:before { content: '\f0e1'; } /* '' */
.icon-download-cloud:before { content: '\f0ed'; } /* '' */
.icon-upload-cloud:before { content: '\f0ee'; } /* '' */
.icon-suitcase:before { content: '\f0f2'; } /* '' */
.icon-bell-alt:before { content: '\f0f3'; } /* '' */
.icon-coffee:before { content: '\f0f4'; } /* '' */
.icon-medkit:before { content: '\f0fa'; } /* '' */
.icon-plus-squared:before { content: '\f0fe'; } /* '' */
.icon-desktop:before { content: '\f108'; } /* '' */
.icon-circle-empty:before { content: '\f10c'; } /* '' */
.icon-quote-left:before { content: '\f10d'; } /* '' */
.icon-quote-right:before { content: '\f10e'; } /* '' */
.icon-spinner:before { content: '\f110'; } /* '' */
.icon-circle:before { content: '\f111'; } /* '' */
.icon-reply-1:before { content: '\f112'; } /* '' */
.icon-folder-empty:before { content: '\f114'; } /* '' */
.icon-folder-open-empty:before { content: '\f115'; } /* '' */
.icon-gamepad:before { content: '\f11b'; } /* '' */
.icon-flag-empty:before { content: '\f11d'; } /* '' */
.icon-reply-all-1:before { content: '\f122'; } /* '' */
.icon-star-half-alt:before { content: '\f123'; } /* '' */
.icon-direction:before { content: '\f124'; } /* '' */
.icon-mic-1:before { content: '\f130'; } /* '' */
.icon-mute:before { content: '\f131'; } /* '' */
.icon-shield:before { content: '\f132'; } /* '' */
.icon-calendar-empty:before { content: '\f133'; } /* '' */
.icon-rocket-1:before { content: '\f135'; } /* '' */
.icon-lock-open-alt:before { content: '\f13e'; } /* '' */
.icon-ok-squared:before { content: '\f14a'; } /* '' */
.icon-doc-inv:before { content: '\f15b'; } /* '' */
.icon-doc-text-inv:before { content: '\f15c'; } /* '' */
.icon-sort-alt-up:before { content: '\f160'; } /* '' */
.icon-thumbs-up-alt:before { content: '\f164'; } /* '' */
.icon-thumbs-down-alt:before { content: '\f165'; } /* '' */
.icon-youtube-play:before { content: '\f16a'; } /* '' */
.icon-apple:before { content: '\f179'; } /* '' */
.icon-male:before { content: '\f183'; } /* '' */
.icon-gittip:before { content: '\f184'; } /* '' */
.icon-moon:before { content: '\f186'; } /* '' */
.icon-box:before { content: '\f187'; } /* '' */
.icon-dot-circled:before { content: '\f192'; } /* '' */
.icon-vimeo-squared:before { content: '\f194'; } /* '' */
.icon-space-shuttle:before { content: '\f197'; } /* '' */
.icon-graduation-cap:before { content: '\f19d'; } /* '' */
.icon-google:before { content: '\f1a0'; } /* '' */
.icon-delicious:before { content: '\f1a5'; } /* '' */
.icon-cube:before { content: '\f1b2'; } /* '' */
.icon-cab:before { content: '\f1b9'; } /* '' */
.icon-taxi:before { content: '\f1ba'; } /* '' */
.icon-database-1:before { content: '\f1c0'; } /* '' */
.icon-file-image:before { content: '\f1c5'; } /* '' */
.icon-circle-notch:before { content: '\f1ce'; } /* '' */
.icon-rebel:before { content: '\f1d0'; } /* '' */
.icon-wechat:before { content: '\f1d7'; } /* '' */
.icon-paper-plane-1:before { content: '\f1d8'; } /* '' */
.icon-paper-plane-empty:before { content: '\f1d9'; } /* '' */
.icon-circle-thin:before { content: '\f1db'; } /* '' */
.icon-sliders:before { content: '\f1de'; } /* '' */
.icon-share:before { content: '\f1e0'; } /* '' */
.icon-bomb:before { content: '\f1e2'; } /* '' */
.icon-soccer-ball:before { content: '\f1e3'; } /* '' */
.icon-binoculars:before { content: '\f1e5'; } /* '' */
.icon-plug:before { content: '\f1e6'; } /* '' */
.icon-paypal:before { content: '\f1ed'; } /* '' */
.icon-cc-visa:before { content: '\f1f0'; } /* '' */
.icon-cc-mastercard:before { content: '\f1f1'; } /* '' */
.icon-cc-discover:before { content: '\f1f2'; } /* '' */
.icon-cc-amex:before { content: '\f1f3'; } /* '' */
.icon-cc-paypal:before { content: '\f1f4'; } /* '' */
.icon-cc-stripe:before { content: '\f1f5'; } /* '' */
.icon-bell-off:before { content: '\f1f6'; } /* '' */
.icon-bell-off-empty:before { content: '\f1f7'; } /* '' */
.icon-trash-1:before { content: '\f1f8'; } /* '' */
.icon-chart-area:before { content: '\f1fe'; } /* '' */
.icon-chart-line:before { content: '\f201'; } /* '' */
.icon-toggle-on:before { content: '\f205'; } /* '' */
.icon-bus:before { content: '\f207'; } /* '' */
.icon-angellist:before { content: '\f209'; } /* '' */
.icon-shekel:before { content: '\f20b'; } /* '' */
.icon-cart-plus:before { content: '\f217'; } /* '' */
.icon-motorcycle:before { content: '\f21c'; } /* '' */
.icon-facebook-official:before { content: '\f230'; } /* '' */
.icon-pinterest:before { content: '\f231'; } /* '' */
.icon-user-plus:before { content: '\f234'; } /* '' */
.icon-user-times:before { content: '\f235'; } /* '' */
.icon-train:before { content: '\f238'; } /* '' */
.icon-battery-4:before { content: '\f240'; } /* '' */
.icon-battery-3:before { content: '\f241'; } /* '' */
.icon-battery-2:before { content: '\f242'; } /* '' */
.icon-battery-1:before { content: '\f243'; } /* '' */
.icon-mouse-pointer:before { content: '\f245'; } /* '' */
.icon-clone:before { content: '\f24d'; } /* '' */
.icon-hourglass-3:before { content: '\f253'; } /* '' */
.icon-calendar-plus-o:before { content: '\f271'; } /* '' */
.icon-industry:before { content: '\f275'; } /* '' */
.icon-map-pin:before { content: '\f276'; } /* '' */
.icon-map-o:before { content: '\f278'; } /* '' */
.icon-map-1:before { content: '\f279'; } /* '' */
.icon-credit-card-alt:before { content: '\f283'; } /* '' */
.icon-envelope-open:before { content: '\f2b6'; } /* '' */
.icon-envelope-open-o:before { content: '\f2b7'; } /* '' */
.icon-user-circle:before { content: '\f2bd'; } /* '' */
.icon-user-circle-o:before { content: '\f2be'; } /* '' */
.icon-twitter-squared:before { content: '\f304'; } /* '' */
.icon-vimeo-circled:before { content: '\f307'; } /* '' */
.icon-facebook-squared:before { content: '\f308'; } /* '' */
.icon-twitter-1:before { content: '\f309'; } /* '' */
.icon-facebook-circled:before { content: '\f30d'; } /* '' */
.icon-facebook-squared-1:before { content: '\f30e'; } /* '' */
.icon-blogger:before { content: '\f314'; } /* '' */
.icon-linkedin-1:before { content: '\f31a'; } /* '' */
.icon-instagram-filled:before { content: '\f31f'; } /* '' */
.icon-box-rect:before { content: '\f322'; } /* '' */
.icon-instagram:before { content: '\f32d'; } /* '' */