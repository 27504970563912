
@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Rubik:400,700");



header {
    width: 100%;
    height: 50px;
    background: #5f5f5f;
    color: black;
    position: fixed;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    display: block;
    -webkit-box-shadow: 0 0 8px #c5c5c4, 0 0 5px #c5c5c4;
    box-shadow: 0 0 8px #c5c5c4, 0 0 5px #c5c5c4;
    position: fixed; }

.logo-site-header img {
    width: 25px;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    margin-top: 17px;
    margin-left: 8px; }

/*Fin diseño de footer*/
/*diseño de navegacion de header*/
.icono1-header img {
    width: 40px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 8px; }

.icono2-header {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    cursor: pointer;}

.styles-nav-header {
    background: none;
    color: #ededed;
    font-size: 14px;
    outline: none;
    border: none;
    cursor: pointer; }

.body-data-site-header {
    position: absolute;
    z-index: 100;
    display: grid;
    grid-template-columns: 1fr 250px;
    grid-template-areas: "item1 item2";
    grid-template-rows: 50px;
    width: 100%; }

#item-area {
    grid-area: item2; }

.body-menu-header {
    margin-right: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 50px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }

#target1 {
    z-index: 100;
    background: #FFFFFF;
    -webkit-border-radius: 0 0 5px 5px;
    border-radius: 0 0 5px 5px;
    height: 100px;
    width: 160px;
    display: none;
    border: 1px solid #59565e; }
#target1 ul {
    color: #261820;
    list-style: none; }
#target1 li {
    padding: 18px 20px 10px 35px; }
#target1 li a {
    text-decoration: none;
    color: #261820;
    margin-top: 20px; }
#target1 li a:hover {
    color: black; }

.top-menu {
    margin-left: 40px;
    width: 50px;
    height: 50px;
    cursor: pointer; }

.container {
    position: relative;
    width: 100%;
    text-align: center;
    top: 90px; }

.overlay {
    position: fixed;
    display: none;
    z-index: -1;
    top: 50px;
    width: 100%;
    height: 100vh;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3); }

.menu-button {
    z-index: 100;
    position: absolute;
    left: 40px;
    height: 4px;
    width: 30px;
    background: #ededed;
    /*Center according to top menu and height of middle bar*/
    top: -webkit-calc(53px / 2 - 5px);
    top: calc(53px / 2 - 5px);
    cursor: pointer;
    -webkit-transition: background ease .3s;
    -o-transition: background ease .3s;
    transition: background ease .3s; }
.menu-button:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 10px;
    height: 100%;
    width: 100%;
    background: #ededed;
    -webkit-transition: all ease .3s;
    -o-transition: all ease .3s;
    transition: all ease .3s; }
.menu-button:after {
    content: '';
    position: absolute;
    top: 10px;
    left: 0;
    height: 100%;
    width: 100%;
    background: #ededed;
    -webkit-transition: all ease .3s;
    -o-transition: all ease .3s;
    transition: all ease .3s; }
.menu-button[data-menu="right"] {
    right: 20px;
    left: auto; }

.menu-button.is-clicked {
    /*Set opacity of middle bar to 0*/
    background: rgba(255, 255, 255, 0);
    -webkit-transition: background ease .3s;
    -o-transition: background ease .3s;
    transition: background ease .3s; }
.menu-button.is-clicked:before {
    background: #ededed;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    bottom: 0;
    -webkit-transition: all ease .3s;
    -o-transition: all ease .3s;
    transition: all ease .3s; }
.menu-button.is-clicked:after {
    background: #ededed;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 0;
    -webkit-transition: all ease .3s;
    -o-transition: all ease .3s;
    transition: all ease .3s; }

.menu-bar {
    position: fixed;
    z-index: 100;
    top: 70px;
    /*Height of body - top menu*/
    height: -webkit-calc(100% - 70px);
    height: calc(100% - 70px);
    color: #fff;
    -webkit-transition: -webkit-transform ease .3s;
    transition: -webkit-transform ease .3s;
    -o-transition: transform ease .3s;
    transition: transform ease .3s;
    transition: transform ease .3s, -webkit-transform ease .3s; }
.menu-bar.left {
    left: 0;
    -webkit-transform: translateX(-350px);
    -ms-transform: translateX(-350px);
    transform: translateX(-350px);
    -webkit-box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3); }
.menu-bar.right {
    right: 0;
    -webkit-transform: translateX(300px);
    -ms-transform: translateX(300px);
    transform: translateX(300px);
    -webkit-box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3); }
.menu-bar.is-active {
    -webkit-transform: translate(0);
    -ms-transform: translate(0);
    transform: translate(0);
    -webkit-transition: -webkit-transform ease .3s;
    transition: -webkit-transform ease .3s;
    -o-transition: transform ease .3s;
    transition: transform ease .3s;
    transition: transform ease .3s, -webkit-transform ease .3s; }

/********responsive design movil***********/
@media screen and (min-width: 1030px) {
    .body-data-site-header {
        grid-template-columns: 1fr 270px; }
    .body-menu-header {
        margin-right: 70px;}
    .overlay-off {
        display: none !important; }
    .menu-bar-on {
        -webkit-transform: translate(0) !important;
        -ms-transform: translate(0) !important;
        transform: translate(0) !important; }
    .menu-button {
        display: none; } }

/********responsive design ipad***********/
