@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Rubik:400,700");

* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

*:after, *:before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.container-required {
    display: flex;
    flex-direction: row;
}

.css-zjq1i3 {
    height: 300px !important;
}

.required {
    color: red;
    padding-top: 5px;
}

.styles-modal {
    font-size: 10px;
    display: grid;
    justify-content: left;
}

.hidden {
    display: none;
}

.opacity {
    opacity: 0.3;
}

.text-modal h1 {
    text-align: center;
    color: #393939;
    font-size: 17px;
    margin-bottom: 10px;
}

.text-modal h2 {
    text-align: left;
    color: #393939;
    font-size: 14px;
    border-bottom: 2px solid #F2CF56;
    width: 100%;
    padding-bottom: 7px;
}

.upled {
    background: #8d8a92;
    padding: 10px;
    color: #ffffff;
    border-radius: 3px;
    margin-left: 5px;
}

.register-modal label, .edit-modal label {
    font-size: 14px;
    display: grid;
    justify-items: left;
}

.register-modal select, .edit-modal select {
    margin-bottom: 10px;
    margin-top: 10px;
}

.edit-modal, .register-modal {
    position: fixed;
    top: 0;
    left: 0;
    -webkit-box-shadow: 0 0 8px #c5c5c4, 0 0 5px #c5c5c4;
    box-shadow: 0 0 8px #c5c5c4, 0 0 5px #c5c5c4;
    padding: 1em 2em;
    background-color: #FFFFFF;
    width: 100%;
    height: 100vh;
    max-height: 100%;
    overflow-y: scroll;
    z-index: 101;
}

.item-modal-img {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#item-modal-p p {
    color: #8b8c8c;
}

.password-modal {
    position: fixed;
    top: 0;
    left: 0;
    -webkit-box-shadow: 0 0 8px #c5c5c4, 0 0 5px #c5c5c4;
    box-shadow: 0 0 8px #c5c5c4, 0 0 5px #c5c5c4;
    padding: 1em 2em;
    background-color: #FFFFFF;
    width: 100%;
    height: 100vh;
    max-height: 100%;
    z-index: 101;
    text-align: left;
}

/************************/

.edit-modal::-webkit-scrollbar {
    width: 8px; /* Tamaño del scroll en vertical */
    height: 8px; /* Tamaño del scroll en horizontal */
    /*display: none;*/
}

.edit-modal::-webkit-scrollbar-thumb {
    background: #a8a8a8;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
.edit-modal::-webkit-scrollbar-thumb:hover {
    background: #1c1c1c;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Ponemos un color de fondo y redondeamos las esquinas del track */
.edit-modal::-webkit-scrollbar-track {
    background: #e1e1e1;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

/************************/
.body-blur {
    background: rgba(60, 61, 61, 0.4);
    width: 100vw;
    height: 100vh;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
}

.button-container-close {
    display: grid;
    margin-top: 20px;
    grid-template-columns: 1fr;
    justify-items: right;
}

@media screen and (min-width: 760px) {
    .edit-modal, .register-modal {
        top: 2%;
        left: 35%;
        padding: 1em 2em;
        background-color: #FFFFFF;
        width: 30%;
        height: auto;
        /*max-height: 850px;*/
        overflow-y: scroll;
        -webkit-border-radius: 10px;
        border-radius: 10px;
    }

    .password-modal {
        top: 35%;
        left: 35%;
        padding: 1em 2em;
        background-color: #FFFFFF;
        width: 30%;
        height: auto;
        -webkit-border-radius: 10px;
        border-radius: 10px;
    }
}
