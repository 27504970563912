@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Rubik:400,700");

#color-body {
    /*color primario*/
    color: #00c1f5;
    /*color secundario*/
    color: #003474;
    /*color secundario*/
    color: #99dbff;
    /*color secundario*/
    color: #001a39;
}

* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    font-family: 'Rubik', sans-serif;
    background: #FFFFFF;
    overflow-x: hidden;
}

.styles-icons {
    margin-left: 5px;

}

.styles-icons2 {
    margin-right: 5px;
    margin-left: 5px;
}

/*Titulos*/
p {
    color: #a6a6a6;
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
}

textarea {
    padding: 10px;
    border: 1px solid #a4a1a9;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    display: block;
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 14px;
    outline: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    width: 100%;
    height: 60px;
    min-width: 120px;
    font-family: sans-serif;
}

.style-texarea {
    height: 100px;
}

input[type=date]:focus, select:focus, textarea:focus, input[type=time]:focus, input[type=text]:focus, input[type=email]:focus, input[type=password]:focus {
    border: 2px solid #19b3f2;

}

input {
    padding: 10px;
    border: 1px solid #a8a8a8;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    display: block;
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 14px;
    outline: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    width: 100%;
    height: 39px;
    min-width: 120px;
}

select {
    padding: 10px;
    border: 1px solid #a8a8a8;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    font-size: 14px;
    outline: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    width: 100%;
    height: 39px;
    min-width: 170px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -ms-appearance: none;
    display: block;
    background: url(../assets/img/icon-slect.png) no-repeat 92% center;
}

button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
}

.formulario .checkbox input[type="checkbox"] {
    display: none;
}

.formulario .checkbox input[type="checkbox"]:checked + label {
    background: #59565e;
    color: #fff;
    height: 35px;
    padding: 8px 8px 10px 15px;
}

.formulario .checkbox input[type="checkbox"]:checked + label:before {
    display: none;
}

.style-checkbox {
    height: 30px;
}

.style-checkbox-on {
    font-weight: 600;
    font-size: 13px;
    color: #e43e51;
    padding: 0;
    margin-top: 5px;
}

.style-checkbox-off {
    font-weight: 600;
    font-size: 13px;
    color: green;
    padding: 0;
    margin-top: 5px;
}

@media screen and (min-width: 760px) {
    select {
        background: url(../assets/img/icon-slect.png) no-repeat 96% center;
    }
}
